import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import useTableFilter from '../hooks/useTableFilter';
import {
  createProductService,
  getProductCategoriesService,
  getProductsService,
} from '../services/products';

export const ProductContentDelivery = {
  cakto: {
    label: 'Área de membros Cakto',
    value: 'cakto',
  },
  external: {
    label: 'Área de membros Externa',
    value: 'external',
  },
  disabled: {
    label: 'Somente para receber pagamentos',
    value: 'disabled',
  },
  emailAccess: {
    label: 'Acesso por e-mail',
    value: 'emailAccess',
  },
};

export const ProductsContext = createContext({
  products: [],
  isFetchingProducts: false,
  categories: [],
  isFetchingCategories: false,
  createProduct: () => {},
  table: {},
  total: 0,
  status: 'active',
  search: '',
  setStatus: () => {},
  setSearch: () => {},
});

const ProductsProvider = ({ children }) => {
  const table = useTableFilter({
    defaultCurrentPage: 1,
  });

  const [status, setStatus] = useState('active,blocked');

  const { data: categories, isFetching: isFetchingCategories } = useQuery(
    ['categories'],
    getProductCategoriesService,
    {
      initialData: [],
    }
  );

  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { data: products, isFetching: isFetchingProducts } = useQuery(
    ['products', status, table.search, table.page, table.rowsPerPage],
    () =>
      getProductsService({
        search: table.search,
        status,
        page: table.page,
        limit: table.rowsPerPage,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    }
  );

  const { mutateAsync: createProduct } = useMutation({
    mutationFn: createProductService,
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries('products');

      enqueueSnackbar('Produto criado com sucesso', { variant: 'success' });

      navigate(`/dashboard/products/${id}/edit?tab=general`);
    },
    onError: () => {
      enqueueSnackbar('Erro ao criar produto', { variant: 'error' });
    },
  });

  const value = useMemo(
    () => ({
      products: products?.products || [],
      categories,
      isFetchingCategories,
      createProduct,
      status,
      setStatus,
      isFetchingProducts,
      table,
      count: products?.count || 0,
    }),
    [products, categories, isFetchingCategories, createProduct, status, isFetchingProducts, table]
  );

  return <ProductsContext.Provider value={value}>{children}</ProductsContext.Provider>;
};

ProductsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProductsProvider;
